<mat-drawer-container #drawerContainer class="base-container"
    [class.menu-open]="viewportWidth > breakPoint && isMenuOpen"
    [class.menu-closed]="viewportWidth > breakPoint && !isMenuOpen">
    <!-- sidenav -->
    <mat-drawer #drawer (window:resize)="resizeEvent($event)" class="sidenav-container" mode="side" opened="isDesktop"
        [class.menu-closed]="viewportWidth > breakPoint && !isMenuOpen">
        <a [title]="'HOME' | translate | titlecase" routerLink="/hino/dashboard"
            style="background-color:#ffffff !important;" class="logo-container">

            <!-- <img *ngIf="user.country.countryId === null || user.country.countryId === 1" src="assets/images/home/hino_header_logo_left_corner.png" class="img-fluid logo-big" alt="">

            <img *ngIf="user.country.countryId === null || user.country.countryId === 1" src="assets/images/home/slogo.png" class="logo-small" alt=""> -->

            <img *ngIf="user.country.countryId === 2" src="assets/images/home/hino_header_logo_left_corner.png" class="img-fluid logo-big" alt="">

            <img *ngIf="user.country.countryId === 2" src="assets/images/home/slogo.png" class="logo-small" alt="">

            <img *ngIf="user.country.countryId === 3" src="assets/images/home/hino_header_logo_left_corner_ca.png" class="img-fluid logo-big" alt="">

            <img *ngIf="user.country.countryId === 3" src="assets/images/home/hino_header_logo_small_ca.png" class="logo-small" alt="">

        </a>
        <!-- <a *ngIf="!selectedCountry" [title]="'HOME' | translate | titlecase" routerLink="/hino/dashboard"
        style="background-color:#ffffff !important;" class="logo-container">
        <img src="assets/images/home/hino_header_logo_left_corner_ca.png" class="img-fluid logo-big" alt="">

        <img src="assets/images/home/hino_header_logo_small_ca.png" class="logo-small" alt="">

    </a> -->
        <ng-container *ngFor="let instance of links">
            <a routerLinkActive="active" [routerLink]="instance.url" matTooltip [matTooltipDisabled]="isMenuOpen"
                style="cursor: pointer;" [matTooltip]="instance.text | translate"
                [matTooltipPosition]="'right'" matTooltipHideDelay="50"
                *ngIf="instance?.urlType?.url_type_code == 'INTERNAL_NG'">

                <img class="module-icon" [src]="'assets/images/menus/'+instance.icon+'.png'" class="logo-small" alt="">
                <span>{{ instance.text | translate }}</span>
                <p>
                    <ng-container *ngIf="instance.icon != 'dtc_error_icon'"><mat-icon>{{ instance.icon }}</mat-icon></ng-container>
                    <ng-container *ngIf="instance.icon == 'dtc_error_icon'"><i class="fas fa-oil-can"></i></ng-container>
                </p>
            </a>
            <a routerLinkActive="active" [routerLink]="instance.url" matTooltip [matTooltipDisabled]="isMenuOpen"
                style="cursor: pointer;" [matTooltip]="instance.text | translate"
                [matTooltipPosition]="'right'" matTooltipHideDelay="50"
                *ngIf="instance?.urlType?.url_type_code != 'INTERNAL_NG'">
                <img class="module-icon" [src]="'assets/images/menus/'+instance.icon+'.png'" class="logo-small" alt="">
                <span>{{  instance.text | translate }}</span>
                <p>
                    <mat-icon>{{ instance.icon }}</mat-icon>
                </p>
            </a>
        </ng-container>
    </mat-drawer>

    <!-- header -->
    <header>
        <!-- header left panel -->
        <div class="left-panel">
            <!-- hamburger menu -->
            <button mat-icon-button color="accent" (click)="drawer.toggle(); isMenuOpen = !isMenuOpen;">
                <mat-icon>menu</mat-icon>
            </button>
            <app-route-title></app-route-title>
            <!--Global Search form -->
            <!-- <form class="form-inline d-flex justify-content-center md-form form-sm active-purple-2 mt-2">
                <input class="form-control form-control-sm mr-3 w-75" type="text" placeholder="Search"
                aria-label="Search VIN" >
                <i class="fas fa-search" aria-hidden="true"></i>
            </form> -->
            <ng-container *ngIf="currentRoute == 'fleetUrl'">
                <app-search [searchPlaceholder]="'VIN'" (searchOutput$)="startGobalSearch($event)" style="width: 180px;"></app-search>
            </ng-container>

            <!-- <ng-container *ngIf="currentRoute == 'fleetUrl'">
                <app-search [searchPlaceholder]="'VIN'" (searchOutput$)="startGobalSearch($event)"></app-search>
            </ng-container> -->

        </div>


        <!-- dropdown for cv admin -->
        <div class="global-dropdown" *ngIf="CVdata == 14" >  
             <!-- New K changes-->
             <label for="countrySelect">Select Country</label>
             <select id="countrySelect" [(ngModel)]="selectedOption" (change)="onSelectionChange($event.target.value)">
               <option value="ALL">All</option>
               <option value="USA">USA</option>
               <option value="CA">Canada</option>
             </select>
        </div>
        
         <!-- haeder right panel -->
        <div class="right-panel">
            <!-- notifications -->
            <!-- <section class="header-icons notifications">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>notifications_none</mat-icon>
                </button>
                <mat-menu xPosition="before" yPosition="below" #menu="matMenu">
                    <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>lorem, ipsum dolor.</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>android</mat-icon>
                        <span>lorem, ipsum dolor.</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>anchor</mat-icon>
                        <span>lorem, ipsum dolor.</span>
                    </button>
                </mat-menu>
            </section> -->
            <!-- settings -->
            <!-- <section class="header-icons settings">
                <button mat-icon-button [matMenuTriggerFor]="settings">
                    <mat-icon>settings</mat-icon>
                </button>
                <mat-menu xPosition="before" yPosition="below" #settings="matMenu">
                    <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>lorem ipsum</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>cached</mat-icon>
                        <span>lorem ipsum</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>alarm</mat-icon>
                        <span>lorem, ipsum dolor.</span>
                    </button>
                </mat-menu>
            </section> -->
            <!-- enlarge -->
            <!-- <section class="header-icons enlarge">
                <button mat-icon-button [matMenuTriggerFor]="enlarge">
                    <mat-icon>fullscreen</mat-icon>
                </button>
                <mat-menu xPosition="before" yPosition="below" #enlarge="matMenu">
                    <button mat-menu-item>
                        <mat-icon>copyright</mat-icon>
                        <span>lorem ipsum</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>invert_colors</mat-icon>
                        <span>lorem ipsum</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>lorem, ipsum dolor.</span>
                    </button>
                </mat-menu>
            </section> -->
            <!-- profile icon -->
            <section class="header-icons profile">
                <button mat-icon-button [matMenuTriggerFor]="profile">
                    <span class="username" [style.color]="startSimulation ?'red':'#bcbdc0'"
                        style="font-weight: 400; font-size: 1rem;" *ngIf="user">{{ user.acccount?.accountName }}</span>
                    <div class="initials"><span>{{ initials }}</span></div><span *ngIf="startSimulation"
                        style="z-index:1000;" [matBadge]="0" matBadgeColor="warn" matBadgeSize="small"></span>
                </button>
                <mat-menu xPosition="before" yPosition="below" #profile="matMenu">
                    <mat-card class="example-card">
                        <mat-card-header>
                            <div mat-card-avatar>
                                <div class="header-initials"><span>{{ initials }}</span></div>
                            </div>
                            <mat-card-title style="word-break: break-word;">{{ user.firstname }} {{ user.lastname }}
                            </mat-card-title>
                            <mat-card-subtitle style="word-break: break-word;"> {{ user.emailAddress }}
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                    <button mat-menu-item (click)="userProfilePage('profile')" class="menu-options">
                        <mat-icon class="menu-options">person</mat-icon>
                        <span> {{'PROFILE' |translate |titlecase }}</span>
                    </button>

                    


                    <button *ngIf="!startSimulation" mat-menu-item (click)="open(aliasUserModal)">
                        <mat-icon class="menu-options">queue_play_next</mat-icon>
                        <span class="menu-options">{{isCorporateUser ? "Simulate User" : "Switch Account"}}</span>
                    </button>
                    
                    <button *ngIf="isCorporateUser" mat-menu-item (click)="open(maintenanceNotify)">
                        <mat-icon class="menu-options">announcement</mat-icon>
                        <span class="menu-options">{{"Maintenance Notification"}}</span>

                    </button>

                    <button *ngIf="startSimulation" mat-menu-item (click)="open(aliasUserModal)">
                        <mat-icon class="menu-options">queue_play_next</mat-icon>
                        <span class="menu-options">{{"Switch Account"  |translate |titlecase }}</span>
                    </button>


                    <button *ngIf="startSimulation" mat-menu-item (click)="closeSimulation()">
                        <mat-icon style="color:red">cancel</mat-icon>
                        <span style="color:red">{{"Close Simulation"|translate |titlecase }}</span>
                    </button>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon class="menu-options">login</mat-icon>
                        <span class="menu-options">{{'LOGOUT' |translate |titlecase }}</span>
                    </button>
                </mat-menu>
            </section>
        </div>
    </header>

    <!-- Alias user modal -->
    <ng-template #aliasUserModal let-modal>
        <app-alias-user [users]="userList" (selectedUser)="selectedUserForSimulation($event)" [fleet]="fleetList" [isCorporate] = "isCorporateUser" [aliasPopupLoading]="aliasPopupLoading"></app-alias-user>
        <div style="background-color:#343a40" class="modal-footer">
            <button (click)="modal.close()" type="button" style="background-color:#343a40;border:1px solid white;"
                class="btn btn-md" (click)="close(aliasUserModal)"><span style="color:#ffffff">Cancel</span></button>
            <button [disabled]="!(userForSimulation) || this.isUserSelected "  (click)="doneAliasUser()" type="button"
                style="background-color:#89a75e;" class="btn btn-md">
                <span style="color:#ffffff">Done</span>
            </button>
        </div>
    </ng-template>

    <ng-template #maintenanceNotify let-modal>
        <div style="display: flex; justify-content: center; align-items: center; background: rgba(0, 0, 0, 0.5); position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; overflow: auto;">
            <form
                id="emailForm"
                (ngSubmit)="onSubmit(emailForm) && modal.close()"
                #emailForm="ngForm"
                style="background: #fff; padding: 20px; border-radius: 8px; width: 90%; max-width: 600px; max-height: 90%; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); overflow-y: auto;"
            >
                <h2 style="text-align: center; margin-bottom: 20px;">Maintenance Notification Email Form</h2>
    
                <div style="display: flex; align-items: center; margin-bottom: 15px;">
                    <label for="subject" style="flex: 1; font-weight: bold;">Email Subject: <span style="color: red;">*</span></label>
                    <input 
                        type="text" 
                        id="subject" 
                        name="subject" 
                        [(ngModel)]="emailData.subject" 
                        placeholder="Enter subject" 
                        required 
                        style="flex: 2; padding: 8px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;" 
                    />
                </div>
    
                <div style="display: flex; align-items: center; margin-bottom: 15px;">
                    <label for="startDate" style="flex: 1; font-weight: bold;">Start Date: <span style="color: red;">*</span></label>
                    <input 
                        type="datetime-local" 
                        id="startDate" 
                        name="startDate" 
                        [(ngModel)]="emailData.startDate" 
                        required 
                        style="flex: 2; padding: 8px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;" 
                    />
                </div>
    
                <div style="display: flex; align-items: center; margin-bottom: 15px;">
                    <label for="endDate" style="flex: 1; font-weight: bold;">End Date: <span style="color: red;">*</span></label>
                    <input 
                        type="datetime-local" 
                        id="endDate" 
                        name="endDate" 
                        [(ngModel)]="emailData.endDate" 
                        required 
                        style="flex: 2; padding: 8px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;" 
                    />
                </div>
    
                <div style="display: flex; align-items: center; margin-bottom: 15px;">
                    <label for="recipients" style="flex: 1; font-weight: bold;">Recipients: <span style="color: red;">*</span></label>
                    <textarea 
                        id="recipients" 
                        name="recipients" 
                        [(ngModel)]="recipientInput" 
                        (ngModelChange)="onRecipientsChange(recipientInput)" 
                        placeholder="Enter recipient emails, separated by commas" 
                        required 
                        style="flex: 2; padding: 8px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; resize: vertical;"
                    ></textarea>
                </div>
    
                <div style="display: flex; align-items: center; margin-bottom: 15px;">
                    <label for="emailBody" style="flex: 1; font-weight: bold;">Email Content: <span style="color: red;">*</span></label>
                    <textarea 
                        id="emailBody" 
                        name="emailBody" 
                        [(ngModel)]="emailData.emailBody" 
                        placeholder="Write your email content here..." 
                        required 
                        style="flex: 2; padding: 8px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; resize: vertical; height: 100px;;"
                    ></textarea>
                </div>

                <div *ngIf="errorMessage" class="error-message" style="text-align: center;">
                    {{ errorMessage }}
                  </div> 
    
                <div style="display: flex; justify-content: space-between; margin-top: 20px;">
                    <button 
                        type="submit" 
                        style="padding: 10px 15px; background-color: #007bff; color: white; border: none; border-radius: 4px; cursor: pointer;"
                    >
                        Send Email
                    </button>
                    <button 
                        type="reset" 
                        style="padding: 10px 15px; background-color: #6c757d; color: white; border: none; border-radius: 4px; cursor: pointer;"
                    >
                        Reset
                    </button>
                    <button 
                        type="button" 
                        (click)="onCancel(emailForm, modal)" 
                        style="padding: 10px 15px; background-color: #6c757d; color: white; border: none; border-radius: 4px; cursor: pointer;"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </ng-template>
      
    <ng-template #helpModal let-modal>
            <div>
                <h4 style="color:rgb(128,128,128,0.8);margin-top:30px;text-align: center;">
                
                    <img *ngIf="user.country.countryId === 3" src="assets/images/home/hino_header_logo_left_corner_ca.png" class="img-fluid logo-big" alt="">
                
                    <img *ngIf="user.country.countryId === 2" src="assets/images/home/hino_header_logo_left_corner.png" class="img-fluid logo-big" alt="" >
                </h4>
            </div>
            <div class="row help-info">
                <p><label>Contact No: 855-510-HINO (4466)</label></p>
                <p><label>ultimatesupport@hino.com</label></p>
            </div>
            <div class="modal-footer center-item">
                <button type="button" class="btn btn-outline-dark btn-sm" (click)="modal.close()">
                    <span>{{ 'OK' |translate |titlecase }}</span>
                </button>
            </div>
    </ng-template>

    <!-- router outlet for base routes -->
    <router-outlet></router-outlet>

</mat-drawer-container>